.App {
  text-align: center;
}
h2 {
  color: white;
}
table,
th,
td {
  border: 1px solid black;
  border-collapse: collapse;
  padding: 10px;
  max-width: 1000px;
}

h1 {
  font-size: 30px;
  font-family: "poppins";
}

.footer-contact-form {
  margin-top: 10px;
}

.footer-link-box {
  padding-top: 56px;
}

.footer-left-box {
  display: flex;
  align-items: center;
  gap: 25px;
}

.footer-icons-container {
  padding-top: 57px;
  display: flex;
  justify-content: center;
  gap: 10px;
}

.checkbox-container {
  display: flex;
  justify-content: center;
  gap: 14px;
  max-width: 518px;
}

.footer-contact-section {
  margin: 30px;
}

.footer-contact-label {
  font-family: "Poppins";
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 33px;
  margin-right: auto;
  color: #ffffff;
}

.header-nav-visible {
  background-color: #282725;
  bottom: 330px;
  display: flex;
  flex-flow: column;
  left: 200px;
  padding: 10px;
  list-style-type: none;
  position: absolute;
  gap: 8px;
  top: 30px;
  max-width: 143px;
  z-index: 2;
  height: min-content;
  box-shadow: 0px 0 0px 1px white;
}

.fa-navicon:before, .fa-reorder:before, .fa-bars:before{
  content: "\f0c9";
  font-size: 28px;
  color: white;
}

.fa-user:before {
  content: "\f007";
  color:white;
  padding-left: 5px;
}

.logo_link-footer {
  font-family: "Poppins";
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 36px;
  color: #ffffff;
}

.footer-checkbox {
  display: flex;
  flex-flow: column;
  align-items: baseline;
  padding-top: 40px;
  font-family: "Poppins";
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  line-height: 18px;
  color: #ffffff;
  gap: 20px;
}

.footer-left-icons {
  display: flex;
}

.footer-left-section {
  display: flex;
  flex-flow: column;
  align-items: baseline;
  width: 100%;
  padding-top: 40px;
}

.footer {
  background-color: #666666;
  width: 100%;
  justify-content: space-between;
  display: flex;
}

.footer-box {
  margin: 0 auto;
  width: 100%;
  max-width: 1214px;
}

.footer-text {
  color: white;
  font-size: 12px;
}

.footer-left-link {
  display: flex;
  flex-flow: column;
  padding-top: 29px;
  text-align: left;
}

.footer-contact-input-message {
  width: 293px;
  height: 151px;
  background: #666666;
  border: 2px solid white;
  color: white;
}

.form-contact {
  display: flex;
  flex-flow: column;
  align-items: baseline;
}

.footer-contact-input {
  height: 36px;
  width: 292px;
  background: #666666;
  border: 2px solid white;
  color: white;
}

.logo-footer{
  max-width: 50%;
}

.form-buttons{
  display:flex;
  flex-flow: row;
  width: 100%;
  gap:20px
}

.footer-contact-submit {
  width: 164px;
  height: 30.85px;
  cursor: pointer;
  background: orange;
  border-radius: 5px;
  color: white;
  border: none;
  font-family: "poppins";
  margin-top: 29px;
  font-style: normal;
  font-weight: 700;
  font-size: 14px;
  line-height: 17px;
  text-align: center;
}

.footer-icons {
  max-width: 100%;
  margin: 0 auto;
  display: flex;
  flex-flow: column;
  gap: 10px;
  padding-right: 166px;
}

.footer-contact-reset {
  width: 121px;
  height: 30.85px;
  cursor: pointer;
  background: red;
  border-radius: 5px;
  color: white;
  border: none;
  font-family: "poppins";
  margin-top: 29px;
  font-style: normal;
  font-weight: 700;
  font-size: 14px;
  line-height: 17px;
  text-align: center;
}

.footer-icons {
  max-width: 100%;
  margin: 0 auto;
  display: flex;
  flex-flow: column;
  gap: 10px;
  padding-right: 166px;
}

.footer-span {
  font-family: "Inter";
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 137.02%;
  text-align: center;
  color: #ff0000;
}

.form-field {
  display: flex;
  flex-flow: column;
  width: 100%;
}

.footer-contact {
  color: white;
  list-style-type: none;
  display: flex;
  font-family: "poppins";
  flex-flow: column;
  gap: 20px;
}

.footer-section {
  display: flex;
  width: 100%;
  flex-flow: column;
  justify-content: center;
}

.footer-contact-header {
  font-family: "poppins";
  font-style: normal;
  font-weight: 700;
  text-align: left;
  font-size: 20px;
  color: white;
  line-height: 19px;
}

.contact {
  list-style-type: none;
  padding: 0px;
  font-family: "poppins";
  font-size: 20px;
}

.slide-container {
  margin-bottom: 20px;
  padding-left: 0px;
  margin-top: 300px;
  padding-right: 0px;
}

.project-box{
  display:grid;
  align-items:baseline;
  justify-content:center;
  padding-bottom:30px;
  gap:20px;
  grid:auto / auto 
}

.header {
  display: flex;
    justify-content: center;
    padding: 10px 0;
    width: 100%;
    height: 62px;
    opacity: 0.6;
    overflow: hidden;
    background-color: black;
}

.gallery {
  margin: 5px;
  border: 1px solid #ccc;
  float: left;
  width: 180px;
}

.each-fade-title {
  position: absolute;
  top: 50%;
  background: black;
  color: white;
  font-weight: bold;
  left: 50%;
  padding: 30px;
  opacity: 0.8;
  max-width: 341px;
  font-size: 20px;
  transform: translate(-50%, -50%);
}

.about-list {
  text-align: left;
  font-family: "poppins";
  list-style-type: decimal;
}

.about-section {
  display: flex;
  flex-flow: column;
  align-items: center;
  /* padding-top: 40px; */
  padding-bottom: 40px;
  justify-content: center;
}

.gallery-box {
  display: flex;
  width: 100%;
  justify-content: center;
}

.gallery:hover {
  border: 1px solid #777;
}

.gallery img {
  width: 100%;
  height: auto;
}

.text-box {
  margin: 0 auto;
  display: flex;
  flex-flow: column;
  align-items: center;
  justify-content: center;
}

.desc {
  padding: 15px;
  text-align: center;
}

.image_slide {
  width: 1000px;
  max-height: 550px;
}

.banner {
  width: 74%;
  position: relative;
  z-index: -1;
  max-height: 431px;
  aspect-ratio: 16/9;
}

.footer-left-logo {
  padding-left: 40px;
}

.main {
  margin-top: 0px;
  width: 100%;
}

.logo-admin {
  max-width: 28%;
}

p {
  text-align: justify;
  margin: 0 auto;
  max-width: 251px;
}

.header a.logo {
  font-size: 25px;
  font-weight: bold;
}

.header-nav {
  display: none
}

.header-nav li {
  white-space: nowrap;
  list-style-type: none;
}

.header-button-box {
  display: flex;
  width: 100%;
  justify-content: flex-end;
  flex-flow: row;
  gap: 47px;
  align-items: center;
  margin-right: 57px;
}

.header-button-contact {
  background-color: #04bc4c;
  border: none;
  color: white;
  padding: 5px 20px 16px 20px;
  text-align: center;
  font-family: "Inter", sans-serif;
  font-style: normal;
  font-weight: 700;
  width: 114px;
  height: 30px;
  font-size: 20px;
  line-height: 20px;
  text-decoration: none;
  display: inline-block;
}

.header-reset {
  font-family: "Poppins";
  font-style: normal;
  font-weight: 300;
  font-size: 16px;
  line-height: 38px;
  color: #adadad;
}

.header-button-login:focus {
  box-sizing: border-box;
  border: 1px solid #ffffff;
  border-radius: 47px;
  width: 131px;
  height: 43px;
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 14px;
  text-align: center;
  color: white;
  background: #04bc4c;
}

.header-content {
  display: flex;
  align-items: center;
}

.header-button-account:focus {
  box-sizing: border-box;
  width: 179px;
  height: 59px;
  border: 1px solid #ffffff;
  border-radius: 47px;
  background-color: #04bc4c;
  color: white;
}

.logo-header {
  display: block;
}

.logo {
  max-width: 500px;
  margin: 0px;
  margin-right: auto;
}

.container {
  margin: 0 auto;
  width: 100%;
  padding: 0 15px;
}

.option-box{
  display:grid;
  align-items:baseline;
  justify-content:center;
  padding-bottom:30px;
  gap:20px;
  font-size:10px;
  grid:auto / auto auto
}

.logo-text {
  color: #f9a407;
}

.header-link {
  font-family: "poppins";
  font-style: normal;
  text-decoration: none;
  font-weight: 400;
  font-size: 20px;
  line-height: 30px;
  color: white;
}

.header-link:hover {
  color: orange;
}

@media screen and (min-width: 991px) {
  .each-fade-title {
    position: absolute;
    top: 50%;
    background: black;
    color: white;
    font-weight: bold;
    padding: 30px;
    opacity: 0.8;
    max-width: 341px;
    font-size: 20px;
  }

  .project-box{
    display:grid;
    align-items:baseline;
    justify-content:center;
    padding-bottom:30px;
    gap:20px;
    grid:auto / auto auto auto
  }

  .option-box{
    display:grid;
    align-items:baseline;
    justify-content:center;
    padding-bottom:30px;
    gap:20px;
    grid:auto / auto auto auto 
  }

  .form-buttons{
    display:flex;
    flex-flow: row;
    width: 100%;
    gap:20px
  }

  .slide-container {
    margin-bottom: 20px;
    padding-left: 150px;
    margin-top: 0px;
    padding-right: 150px;
  }

  .about-list {
    text-align: left;
    margin-left: 52px;
    font-family: "poppins";
    list-style-type: decimal;
  }

  .fa-navicon:before, .fa-reorder:before, .fa-bars:before{
    display: none;
  }

  .footer-section {
    display: flex;
    width: 100%;
    flex-flow: row;
    padding-top: 40px;
    justify-content: center;
  }

  .fa-user:before{
    display: none;
  }

  .container {
    margin: 0 auto;
    max-width: 1339px;
  }
  /* .logo {
    padding-left: 0px;
  } */
  .header-nav {
    display: flex;
    align-items: center;
    gap: 16px;
    padding: 0px;
    margin: 5px 15px 0 15px;
    /* height: 102px; */
  }
  .image_slide {
    width: 100%;
    max-height: 550px;
  }
  p {
    max-width: 1125px;
    text-align: justify;
    font-family: "poppins";
    margin: 0 auto;
  }
  .header-nav-visible {
    display: none;
  }
}
